import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import WalletApiService from "@/core/services/api.service.wallet";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
    props: {
        modal: {
            default: false
        },
        value: Boolean
    },

    name: "shop-gifts",
    data() {
        return {
            requiredRule: [v => !!v || "Field is required"],
            isSaving: false,
            shopId: null,
            shopName: "",
            valid: false,
            addGiftDisable: false,
            giftValue: "",
            validTo: null,
            shopGifts: [],
        };
    },
    async created() {
        await WalletApiService.init();
        this.shopId = this.$route.params.shopId;
        this.shopName = this.$route.params.shopName;
        this.$log.debug("shopid: ", this.shopId);
        this.$store.dispatch(SET_BREADCRUMB, [{title: this.$i18n.t ( "swGift.giftsForShop" ) + this.shopName}]);
        this.retrieveGifts();
    },

    methods: {
        async delRecord(item) {
            if (
                await this.$refs.confirm.open(
                    "Confirm",
                    "Are you sure you want to delete gift created on " +
                    new Date(item.created).toLocaleString() + " with value " + item.value +
                    " ?"
                )
            ) {
                this.deleteGift(item);
            }
        },
        deleteGift(item) {
            this.loading = true;
            if (item.id != null) {
                WalletApiService.get(`/CancelWalletGift`, `${item.id}`)
                    .then(response => {
                        this.$log.debug("Cancel wallet gift: ", item.id);
                        this.$log.debug("Response: ", response);
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => (this.retrieveGifts(), this.loading = false));
            }
        },
        retrieveGifts() {
            if (this.shopId != null) {
                WalletApiService.get(`/GetAllWalletGiftsByShopId`, `${this.shopId}`)
                    .then(response => {
                        this.$log.debug("Shop gifts: ", response.data);
                        this.shopGifts = response.data;
                    })
                    .catch(error => {
                        this.$log.error("Error: ", error);
                        this.errored = true;
                    })
                    .finally(() => (this.loading = false));
            }
        },
        previousState() {
            this.$router.go(-1);
        },
        validateAndSave() {
            if (this.$refs.form.validate()) {
                this.saveShopTags(this.tags, this.shopId);
            }
        },
        addGiftToShop() {
            this.$log.debug("Tags for saving: " + this.giftValue);
            this.$log.debug("shopId: " + this.shopId);
            let validToTimeStamp = this.validTo != null ? new Date(this.validTo).valueOf() : null;
            this.$log.debug("validTo: " + validToTimeStamp);
            this.addGiftDisable = true;
            let shopGift = {};
            shopGift["shopId"] = this.shopId.toString();
            shopGift["value"] = this.giftValue;
            shopGift["validTo"] = validToTimeStamp;

            WalletApiService.post(`/CreateNewWalletGift`, shopGift)
                .then(response => {
                    this.$log.debug("Shop gift created: " + response);
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => {
                    this.retrieveGifts();
                    this.addGiftDisable = false;
                    this.loading = false;
                    this.isSaving = false;
                    this.giftValue = "";
                    this.validTo = null;
                    this.$refs.datetimepickerValidTo.date = null;
                    this.$refs.datetimepickerValidTo.time = null;
                });
        }
    },
    components: {
        ConfirmDialog
    },
    computed: {
        ...mapGetters(["currentUserPersonalInfo"]),
        giftTableHeaders(){return [
            {text: this.$i18n.t ( "swGift.value" ), value: "value", sortable: false},
            {text: this.$i18n.t ( "common.created" ), value: "created", sortable: false},
            {text: this.$i18n.t ( "common.validTo" ), value: "validTo", sortable: false},
            {text: this.$i18n.t ( "swGift.used" ), value: "used", sortable: false},
            {text: this.$i18n.t ( "common.delete" ), value: "delete", sortable: false}
        ]
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit("input", value);
                }
            }
        }
    }
};
