var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0 pt-5"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"800"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('swGift.gifts'))+" ")]),_c('v-card-actions',[_c('v-col',[_c('v-spacer')],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('swGift.gValue')},model:{value:(_vm.giftValue),callback:function ($$v) {_vm.giftValue=$$v},expression:"giftValue"}})],1),_c('v-col',[_c('v-datetime-picker',{ref:"datetimepickerValidTo",attrs:{"label":_vm.$t('swGift.gValid'),"time-format":"HH:mm:ss"},model:{value:(_vm.validTo),callback:function ($$v) {_vm.validTo=$$v},expression:"validTo"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"tile":"","color":"success","disabled":_vm.addGiftDisable},on:{"click":function($event){return _vm.addGiftToShop()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('swGift.addNew'))+" ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.giftTableHeaders,"items":_vm.shopGifts,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created).toLocaleString())+" ")]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.validTo != null ? new Date(item.validTo).toLocaleString() : "")+" ")]}},{key:"item.used",fn:function(ref){
var item = ref.item;
return [(!item.used)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"grey","text-color":"white"}},[_vm._v("Not used")]):_vm._e(),(item.used)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v("Used")]):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1)]}}])}),_c('ConfirmDialog',{ref:"confirm"}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"deep-purple lighten-2","text":""},on:{"click":function($event){return _vm.previousState()}}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }